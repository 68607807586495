const today = new Date();

export const getToday = (): string =>
    String(today.getFullYear()) +
    '-' +
    String(today.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(today.getDate()).padStart(2, '0');

export const dateDiffInDays = (a: Date, b: Date = today): number =>
    (a.getTime() - b.getTime()) / (1000 * 3600 * 24);
