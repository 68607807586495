import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { fetchTraffic, generateAuthHeader } from '../api/api';
import { Traffic } from '../types/Traffic';
import userStore from '../store/root';

const Metrics = () => {
    const userSate = userStore();
    const auth = generateAuthHeader(userSate);
    const [traffic, setTraffic] = useState(new Array<Traffic>());

    const aClassPrice = 1730;
    const bClassPrice = 140;

    const formatNumber = (num: number): string => {
        return num.toLocaleString('en-US');
    };

    const renderItem = (traffic: Traffic) => {
        const price = Math.ceil(
            (traffic.classA / 1000000) * aClassPrice + (traffic.classB / 1000000) * bClassPrice,
        );

        return (
            <tr key={traffic._id}>
                <td>
                    {traffic.year}/{traffic.month}
                </td>
                <td>{formatNumber(traffic.classA)} db</td>
                <td>{formatNumber(traffic.classB)} db</td>
                <td>{formatNumber(price)} Ft</td>
            </tr>
        );
    };

    useEffect(() => {
        fetchTraffic(auth).then((data) => setTraffic(data));
    }, []);

    return (
        <Container fluid>
            <Row className="justify-content-md-center">
                <Col xs={12} md={4}>
                    <h1 className="text-center">365 hirdetéskezelő S3 forgalom</h1>
                    <i>A</i> osztályú műveletek: {aClassPrice} Ft/1.000.000 művelet, <i>B</i>{' '}
                    osztályú műveletek: {bClassPrice} Ft/1.000.000 művelet
                    <br />
                    Az árkalkuláció tájékoztató jellegű és mindig az aktuális árfolyamok szerint
                    kerül kiszámításra.
                </Col>
            </Row>
            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th>Év-Hónap</th>
                            <th>A osztályű műveletek</th>
                            <th>B osztályű műveletek</th>
                            <th>Árkalkuláció (nettó)</th>
                        </tr>
                    </thead>
                    <tbody>{traffic.map((t) => renderItem(t))}</tbody>
                </Table>
            </Row>
        </Container>
    );
};

export default Metrics;
