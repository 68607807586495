import React, { useState } from 'react';
import userStore from '../store/root';
import axios from 'axios';
import sha256 from 'crypto-js/sha256';
import { generateBackendUrl } from '../api/api';

const Login = () => {
    const userSate = userStore();
    const [userName, setUserName] = useState('');
    const [userPassword, setUserPassword] = useState('');

    const submit = () => {
        const pwHash = sha256(userPassword).toString();
        const credentials = { userName, userPassword: pwHash };

        axios.post(generateBackendUrl('/login'), credentials).then((response) => {
            if (response?.data?.logged === true) {
                userSate.updateCredentials(userName, pwHash, true);
            } else {
                userSate.updateCredentials(userName, userPassword, false);
                alert('Hibás bejelentkezési adatok!');
            }
        });
    };

    const handleUserNameChange = (event: { target: { value: string } }) => {
        setUserName(event.target.value);
    };

    const handleUserPasswordChange = (event: { target: { value: string } }) => {
        setUserPassword(event.target.value);
    };

    return (
        <div>
            <h1>Bejelentkezés</h1>

            <div className="form-outline mb-4">
                <label className="form-label">Felhasználónév</label>
                <input
                    type="text"
                    id="username"
                    onChange={handleUserNameChange}
                    value={userName}
                    className="form-control"
                    required
                />
            </div>

            <div className="form-outline mb-4">
                <label className="form-label">Jelszó</label>
                <input
                    type="password"
                    id="password"
                    onChange={handleUserPasswordChange}
                    value={userPassword}
                    className="form-control"
                    required
                />
            </div>

            <button onClick={submit} className="btn btn-success">
                Bejelentkezés
            </button>
        </div>
    );
};

export default Login;
