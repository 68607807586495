import React from 'react';
import Login from './pages/Login';
import userStore from './store/root';
import Admin from './pages/Admin';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PageNotFound from './pages/PageNotFound';
import Traffic from './pages/Metrics';

const App = () => {
    const userSate = userStore();

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/admin" element={userSate.logged ? <Admin /> : <Login />} />
                <Route path="/metrics" element={userSate.logged ? <Traffic /> : <Login />} />
                <Route path="/login" element={<Navigate to="/admin" />} />
                <Route path="/" element={<Navigate to="/admin" />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
