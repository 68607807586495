import axios from 'axios';
import { Banner } from '../types/Banner';
import { Traffic } from '../types/Traffic';
import { AuthHeader } from '../types/Auth';
import { UserSate } from '../types/States';

export const generateBackendUrl = (uri: string): string => {
    const host =
        process.env?.NODE_ENV === 'production'
            ? 'https://365commercial-portal.sootsoft.hu'
            : 'http://localhost:3001';

    return `${host}${uri}`;
};

export const generateAuthHeader = (user: UserSate): AuthHeader => {
    return {
        auth: {
            username: user.username,
            password: user.password,
        },
    };
};

export const fetchBanners = async (site: string): Promise<Array<Banner>> => {
    const data = new Array<Banner>();

    await axios
        .get(generateBackendUrl(`/banners/${site}`))
        .then((response) => response.data.map((b: Banner) => data.push(b)));

    return data;
};

export const insertBanner = async (banner: Banner, auth: AuthHeader) => {
    console.log(auth);
    await axios.post(generateBackendUrl('/banner'), banner, auth);
};

export const deleteBanner = async (key: string, auth: AuthHeader) => {
    await axios.delete(generateBackendUrl(`/banner/${key}`), auth);
};

export const fetchTraffic = async (auth: AuthHeader): Promise<Array<Traffic>> => {
    const data = new Array<Traffic>();

    await axios
        .get(generateBackendUrl(`/traffic`), auth)
        .then((response) => response.data.map((t: Traffic) => data.push(t)));
    return data;
};
