import {
    S3Client,
    PutObjectCommand,
    DeleteObjectCommand,
    DeleteObjectCommandOutput,
    PutObjectCommandOutput,
} from '@aws-sdk/client-s3';

/** The maximum uploadable file size to S3 in bytes */
export const MAX_FILE_SIZE = 10485760;

const Bucket = '365commercial';

const client = new S3Client({
    endpoint: 'https://s3.sootsoft.hu',
    credentials: {
        accessKeyId: 'd072f317a861ddaa07685da045a2401c',
        secretAccessKey: 'ddfeeadd21eef7aad2eb24f816622d233c8fbe13086bcfb6844689296e29fc31',
    },
    region: 'auto',
    forcePathStyle: true, // Fontos, ha nem AWS S3-at használunk, enélkül nem működik
});

export const uploadFileToS3 = async (Key: string, Body: File): Promise<PutObjectCommandOutput> => {
    const command = new PutObjectCommand({
        Bucket,
        Key,
        Body,
        ContentType: Body.type,
    });

    const response = await client.send(command);

    return response;
};

export const deleteFileFromS3 = async (Key: string): Promise<DeleteObjectCommandOutput> => {
    const command = new DeleteObjectCommand({
        Bucket,
        Key,
    });

    const response = await client.send(command);

    return response;
};

export const generateKey = (length = 48): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
};
