import React, { useEffect, useState } from 'react';
import { Banner, BannerPosition } from '../types/Banner';
import { Form, Table, Button, Row, Col, Container, Badge } from 'react-bootstrap';
import { bannerPositions } from '../constants/Positions';
import { deleteBanner, fetchBanners, generateAuthHeader, insertBanner } from '../api/api';
import { MAX_FILE_SIZE, deleteFileFromS3, generateKey, uploadFileToS3 } from '../helper/S3';
import { Site } from '../constants/Enums';
import szegedLogo from '../assets/images/szeged365.png';
import kecskemetLogo from '../assets/images/kecskemet365.png';
import { dateDiffInDays, getToday } from '../helper/Date';
import userStore from '../store/root';

const Admin = () => {
    const userSate = userStore();
    const auth = generateAuthHeader(userSate);
    const MAX_BANNERS = 100;

    const [selectedSite, setSelectedSite] = useState('');
    const [banners, setBanners] = useState(new Array<Banner>());
    const [newBannerUrl, setNewBannerUrl] = useState('');
    const [newBannerPosition, setNewBannerPosition] = useState(bannerPositions[0].id);
    const [newBannerStartDate, setNewBannerStartDate] = useState(getToday());
    const [newBannerEndDate, setNewBannerEndDate] = useState('');
    const [newBannerImg, setNewBannerImg] = useState<File | undefined>();

    const handleAddSubmit = () => {
        if (
            selectedSite &&
            newBannerUrl &&
            newBannerPosition &&
            newBannerStartDate &&
            newBannerEndDate &&
            newBannerImg
        ) {
            if (newBannerImg?.size > MAX_FILE_SIZE) {
                alert('Túl nagy fájlméret! Maximum megabyte: ' + MAX_FILE_SIZE / 1024 / 1024);
            } else if (banners.length >= MAX_BANNERS) {
                alert('A feltöltött bannerek száma elérte a maximumot: ' + MAX_BANNERS);
            } else if (
                !newBannerUrl.startsWith('https://') &&
                !newBannerUrl.startsWith('http://')
            ) {
                alert('Az URL-nek https://-el vagy http://-el kell kezdődnie!');
            } else {
                const key = `${selectedSite}/${generateKey()}`;
                const findPosition = bannerPositions.find(
                    (p) => p.id === newBannerPosition,
                ) as BannerPosition;

                const newBanner = {
                    endDate: newBannerEndDate,
                    href: newBannerUrl,
                    key,
                    position: findPosition,
                    site: selectedSite,
                    startDate: newBannerStartDate,
                } as Banner;

                Promise.all([
                    insertBanner(newBanner, auth),
                    uploadFileToS3(newBanner.key, newBannerImg),
                ])
                    .then(() => {
                        fetchBanners(selectedSite).then((r) => setBanners(r));
                        setNewBannerStartDate('');
                        setNewBannerEndDate('');
                        setNewBannerUrl('');
                        alert('Sikeres hozzáadás!');
                    })
                    .catch((error) => alert(error));
            }
        } else {
            alert('Minden mező kitöltése kötelező!');
        }
    };

    const handleDeleteSubmit = (key: string) => {
        Promise.all([deleteBanner(key, auth), deleteFileFromS3(key)]).then(() => {
            fetchBanners(selectedSite).then((r) => setBanners(r));
            alert('Sikeres törlés!');
        });
    };

    const createBadge = (banner: Banner) =>
        dateDiffInDays(new Date(banner.endDate)) < -1 ? <Badge bg="danger">LEJÁRT</Badge> : null;

    useEffect(() => {
        if (banners) {
            const updatedBanners = new Array<Banner>();

            banners.map((b) => {
                if (dateDiffInDays(new Date(b.endDate)) < -1) {
                    deleteBanner(b.key, auth);
                    deleteFileFromS3(b.key);
                } else {
                    updatedBanners.push(b);
                }
            });

            if (updatedBanners.length !== banners.length) {
                setBanners(updatedBanners);
                alert(
                    `Összesen ${
                        banners.length - updatedBanners.length
                    } db lejárt banner került törlésre!`,
                );
                console.log(
                    `Összesen ${
                        banners.length - updatedBanners.length
                    } db lejárt banner került törlésre!`,
                );
            }
        }
    }, [banners]);

    const renderItem = (banner: Banner) => {
        return (
            <tr key={banner.key}>
                <td>
                    <img
                        src={`https://s3.sootsoft.hu/365commercial/${banner.key}`}
                        width="300"
                        alt={banner.key}
                    />
                </td>
                <td>{banner.href}</td>
                <td>{banner?.position.name}</td>
                <td>{banner.startDate}</td>
                <td>
                    {banner.endDate} {createBadge(banner)}
                </td>
                <td>
                    <Button
                        as="input"
                        variant="secondary"
                        type="submit"
                        value="Törlés"
                        onClick={() => handleDeleteSubmit(banner.key)}
                    />
                </td>
            </tr>
        );
    };

    useEffect(() => {
        if (selectedSite === Site.KECSKEMET || selectedSite === Site.SZEGED) {
            fetchBanners(selectedSite).then((r) => setBanners(r));
        }
    }, [selectedSite]);

    return (
        <Container fluid>
            <Row className="justify-content-md-center">
                <Col xs={12} md={4}>
                    <h1 className="text-center">365 hirdetéskezelő</h1>
                    <Form.Label htmlFor="siteSelecter">Oldal kiválasztása</Form.Label>
                    <Form.Select
                        id="siteSelecter"
                        aria-describedby="siteSelectHelpBlock"
                        onChange={(e) => setSelectedSite(e.target.value)}
                    >
                        <option key={'nincs'} value={''}>
                            Nincs
                        </option>
                        <option key={Site.SZEGED} value={Site.SZEGED}>
                            Szeged365
                        </option>
                        <option key={Site.KECSKEMET} value={Site.KECSKEMET}>
                            Kecskemét365
                        </option>
                    </Form.Select>
                    <Form.Text id="siteSelectHelpBlock" muted>
                        Válassza ki a fenti listából a kezelendő hírportált!
                    </Form.Text>
                    <p>
                        S3 adathasználati statisztika <a href="/metrics">megtekintetése</a>
                    </p>
                </Col>
            </Row>
            {selectedSite ? (
                <>
                    <Row className="justify-content-md-center">
                        <Col xs={12} md={4}>
                            <img
                                src={selectedSite === Site.SZEGED ? szegedLogo : kecskemetLogo}
                                width="300"
                                alt={'Site logo'}
                            />{' '}
                        </Col>
                    </Row>
                    <Row>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Bannerkép</th>
                                    <th>URL</th>
                                    <th>Pozíció</th>
                                    <th>Kezdő dátum</th>
                                    <th>Befejező dátum</th>
                                    <th>Művelet</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <input
                                            type="file"
                                            onChange={(e) =>
                                                setNewBannerImg(
                                                    e.target?.files?.length
                                                        ? e.target?.files[0]
                                                        : undefined,
                                                )
                                            }
                                            accept="image/*"
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="url"
                                            placeholder="https://hova.hu"
                                            value={newBannerUrl}
                                            onChange={(e) => setNewBannerUrl(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <Form.Select
                                            onChange={(e) => setNewBannerPosition(e.target.value)}
                                        >
                                            {bannerPositions.map((o) => (
                                                <option key={o.id} value={o.id}>
                                                    {o.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </td>
                                    <td>
                                        <Form.Control
                                            type={'date'}
                                            min={getToday()}
                                            value={newBannerStartDate}
                                            onChange={(e) => setNewBannerStartDate(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="date"
                                            min={getToday()}
                                            value={newBannerEndDate}
                                            onChange={(e) => setNewBannerEndDate(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <button
                                            onClick={handleAddSubmit}
                                            type={'submit'}
                                            className={'btn btn-primary'}
                                        >
                                            Hozzáadás
                                        </button>
                                    </td>
                                </tr>
                                {banners && banners.map((b) => renderItem(b))}
                            </tbody>
                        </Table>
                    </Row>
                </>
            ) : null}
        </Container>
    );
};

export default Admin;
