import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { UserSate } from '../types/States';

const userStore = create<UserSate>()(
    persist(
        (set) => ({
            username: '',
            password: '',
            logged: false,
            updateCredentials: (username: string, password: string, logged: boolean) =>
                set(() => ({ username, password, logged })),
        }),
        {
            name: 'user-storage', // name of item in the storage (must be unique)
            storage: createJSONStorage(() => sessionStorage), // (optional) by default the 'localStorage' is used
            partialize: (state) =>
                Object.fromEntries(Object.entries(state).filter(([key]) => !['foo'].includes(key))),
        },
    ),
);

export default userStore;
