import { BannerPosition } from '../types/Banner';

export const bannerPositions: Array<BannerPosition> = [
    {
        id: 'fejlec-feletti-hirdetes',
        name: 'Fejléc feletti hirdetés (wallpaper)',
        category: 'Globális bannerek',
        width: 1600,
        height: 300,
    },
    {
        id: 'fejlec-hirdetes',
        name: 'Fejléc hirdetés (leaderboard)',
        category: 'Globális bannerek',
        width: 728,
        height: 90,
    },
    {
        id: 'lablec-feletti-hirdetes',
        name: 'Lábléc feletti hirdetés',
        category: 'Globális bannerek',
        width: 728,
        height: 90,
    },
    {
        id: 'fejlec-alatti-hirdetes',
        name: 'Fejléc alatti hirdetés',
        category: 'Globális bannerek',
        width: 728,
        height: 90,
    },
    {
        id: 'medium-rectangle',
        name: 'Medium rectangle',
        category: 'Globális bannerek',
        width: 300,
        height: 250,
    },
    {
        id: 'half-page',
        name: 'Half page',
        category: 'Globális bannerek',
        width: 300,
        height: 600,
    },
    {
        id: 'kezdolap-superleaderboard-felso',
        name: 'Superleaderboard (felső)',
        category: 'Kezdőlapi bannerek',
        width: 1168,
        height: 190,
    },
    {
        id: 'kezdolap-szeged365-friss-feletti',
        name: 'Friss feletti',
        category: 'Kezdőlapi bannerek',
        width: 780,
        height: 485,
    },
    {
        id: 'kezdolap-kozelet-feletti',
        name: 'Közélet feletti',
        category: 'Kezdőlapi bannerek',
        width: 780,
        height: 485,
    },
    {
        id: 'kezdolap-kikapcs-feletti',
        name: 'Kikapcs feletti (program)',
        category: 'Kezdőlapi bannerek',
        width: 780,
        height: 485,
    },
    {
        id: 'kezdolap-sport-feletti',
        name: 'Sport feletti',
        category: 'Kezdőlapi bannerek',
        width: 780,
        height: 485,
    },
    {
        id: 'kezdolap-szegedi-arcok-feletti',
        name: 'Szegedi arcok feletti',
        category: 'Kezdőlapi bannerek',
        width: 780,
        height: 485,
    },
    {
        id: 'kezdolap-mindenmas-feletti',
        name: 'Mindenmás feletti',
        category: 'Kezdőlapi bannerek',
        width: 780,
        height: 485,
    },
    {
        id: 'kezdolap-maximalista-regio-feletti',
        name: 'Maximalista feletti (gastro)',
        category: 'Kezdőlapi bannerek',
        width: 780,
        height: 485,
    },
    {
        id: 'kezdolap-video365-feletti',
        name: 'Superleaderboard (alsó)',
        category: 'Kezdőlapi bannerek',
        width: 1168,
        height: 190,
    },
    {
        id: 'cikk-feletti-hirdetes',
        name: 'Cikk feletti hirdetés',
        category: 'Cikk bannerek',
        width: 728,
        height: 90,
    },
    {
        id: 'cikk-szovege-feletti-hirdetes',
        name: 'Cikk szövege feletti hirdetés',
        category: 'Cikk bannerek',
        width: 780,
        height: 485,
    },
    {
        id: 'cikk-szovege-alatti-hirdetes',
        name: 'Cikk szövege alatti hirdetés',
        category: 'Cikk bannerek',
        width: 780,
        height: 485,
    },
    {
        id: 'cikk-alatti-hirdetes',
        name: 'Cikk alatti hirdetés',
        category: 'Cikk bannerek',
        width: 780,
        height: 485,
    },
    {
        id: 'cikken-beluli-hirdetes-1',
        name: 'Cikken belüli hirdetés #1',
        category: 'Cikk bannerek',
        width: 780,
        height: 485,
    },
    {
        id: 'cikken-beluli-hirdetes-2',
        name: 'Cikken belüli hirdetés #2',
        category: 'Cikk bannerek',
        width: 780,
        height: 485,
    },
    {
        id: 'cikken-beluli-hirdetes-3',
        name: 'Cikken belüli hirdetés #3',
        category: 'Cikk bannerek',
        width: 780,
        height: 485,
    },
];
